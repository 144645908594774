<template>
  <div>
    <Loading :loading="loading"></Loading>
    RLP Web Service
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import CryptoJS from 'crypto-js';
import axios from "axios";

export default {
  name: "RLP",
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      secretKey: 'VswFYGzh2k45N7Q8R9@TBuCVexFY.G2J3K4N6p7Q%9SATB-VdWeXFZHJ3m5N',
      secretKey2: 'yPPLPWiyjAP7KwWRJMakBTWFTcAXwefUBCqRXw9tyaENYxiPTmiQDtTVrFap9Lz4', // RLP servisine giderken
      userData: null,
    }
  },
  beforeMount() {
    const query = this.$route.query;
    if (query.sso) {
      const decode = decodeURIComponent(atob(query.sso));
      const bytes = CryptoJS.AES.decrypt(decode, this.secretKey).toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(bytes);
      this.ueslmsCheckUser()
    }
  },
  methods: {
    ueslmsCheckUser() {
      axios.post('https://www.ueslms.com/api/v2/rlp/user/check', {user: this.userData})
          .then(response => {
            if (response.data.success) {
              this.userData = response.data.user;
              this.goRLPSso()
            } else {
              this.ueslmsCreate()
            }
          })
          .catch(response => {
            console.log('ERR', response)
          })
    },

    ueslmsCreate() {
      axios.post('https://www.ueslms.com/api/v2/rlp/user/create', this.userData)
          .then(response => {
            if (response.data.success) {
              this.userData = response.data.user;
              this.goRLPSso()
            } else {
              console.log('ERR', response)
            }
          })
          .catch(response => {
            console.log('ERR', response)
          })
    },

    goRLPSso() {
      const textJson = JSON.stringify(this.userData);
      const uriText = decodeURIComponent(textJson);
      const ciphertext = CryptoJS.AES.encrypt(uriText, this.secretKey2).toString();
      window.location.href = "https://richmondlp.com/ueshub/sessions/new?token=" + btoa(ciphertext);
    },

  }
}
</script>
