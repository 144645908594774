<template>
  <div class="flex-center position-ref">
    <div class="content">
      <Loading :loading="loading"></Loading>
      <img src="/assets/logos/efs-plus.png">
      <h3 v-if="this.$route.query.sso">Lütfen bekleyiniz, ilgili platforma yönlendiriliyorsunuz.</h3>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from "moment";
import CryptoJS from "crypto-js";
import Loading from "@/components/Loading.vue";

export default {
  name: "EFS",
  components: {Loading},
  data() {
    return {
      loading: false,
      secretKey: 'Pbt6DND9k5tu7Tdj284-1szLNPEXwcVznFMN4G@UdQtZ7.GEU7!BPy',
      userData: null,
      token: "",
      headers: {
        alg: "RS256",                       // (the algorithm used to sign the token)
        use: "sig",
        kid: "sig-1694081434",                     // (the key pair identifier)
        typ: "JWT"
      },
      payloads: {
        iss: "https://service.ueslms.com/#/efs",  // (the issuer identifier; example: https://example.com)
        sub: null,          // (the unique user identifier; example: user_id_123)
        // aud: "UES_EFS",              // (the client identifier; example: ell-tech)
        exp: 0,                    // (the token’s expiry time in UNIX time; example: 1691145093)
        iat: 0,                    // (the token’s issued time in UNIX time; example: 1691144793)
        name: null,
        user_id: null,                  // unique user identifier
        // email: "33142539336",               // (the user’s email) // Or the unique username
        username: null,            // Or the user’s email
        given_name: null,                  // the user’s first name
        family_name: null,              // the user’s last name
        // user_license_id: "UES_EFS",           // unique ID provided by ELL that corresponds to the license
        role_type: null,               // student or teacher
        // class: "2-B",                       // for student
        // "classes": [],                     // list of classes and sections (if applicable) for teachers as an array For example: [‘1A’, ‘1C’,’4A’] OR [‘English Grade 1’]
        groups: [
          {
            id: null,
            name: null,
            section: null,
            campus_id: null,
            campus_name: null,
            institution_id: null,
            institution_name: null,
          }
        ],
        // "class_section: "",
        // "gender: "female",                   // (the user’s gender)
        // "picture: "http://example.com/janedoe/me.jpg", // (the user’s profile picture as a URL)
        // "name: "Jane Doe",
        // "birthdate: "0000-10-31",
      },
    }
  },
  beforeMount() {
    const query = this.$route.query;
    if (query.sso) {
      this.loading = true
      // gelen data çözülüyor
      const decode = decodeURIComponent(atob(query.sso));
      const bytes = CryptoJS.AES.decrypt(decode, this.secretKey).toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(bytes);
      this.ueslmsCheckUser();
    }
  },
  methods: {
    setTokenExpiredTime() {
      // Set the token to expire in 1 hour
      const expiration = moment().add(1, 'hours').unix();
      this.payloads.exp = expiration;
    },

    setTokenIssuedTime() {
      // Use Moment.js to get the current time
      const issuedAt = moment().unix(); // Current time in UNIX time
      this.payloads.iat = issuedAt;
    },

    setUserDatas() {
      this.payloads.aud = this.userData.efs_aud
      this.payloads.user_license_id = this.userData.efs_aud
      this.payloads.sub = this.userData.user_idx.toString()
      this.payloads.name = `${this.userData.first_name} ${this.userData.last_name}`
      this.payloads.user_id = this.userData.user_idx.toString()
      this.payloads.username = this.userData.username.toString()
      this.payloads.given_name = this.userData.first_name
      this.payloads.family_name = this.userData.last_name
      this.payloads.role_type = this.userData.role_type
      if (this.userData.role_type === 'student') {
        // console.log('this.userData stu 1', this.userData)
        this.payloads.groups[0].id = this.userData.class_id.toString()
        this.payloads.groups[0].name = this.userData.grade
        this.payloads.groups[0].section = this.userData.branch
        this.payloads.groups[0].campus_id = this.userData.campus_idx.toString()
        this.payloads.groups[0].campus_name = this.userData.campus_name
        this.payloads.groups[0].institution_id = this.userData.school_id.toString()
        this.payloads.groups[0].institution_name = this.userData.school_namex
        // console.log('this.userData stu 2', this.userData)
        // console.log('this.payloads', this.payloads)
      } else {
        // console.log('this.userData', this.userData)
        // ogretmen
        this.userData.class.forEach((value, index) => {
          // console.log('value', value)
          // console.log('index', index)
          var obj = {}
          var snf = value.grade + '-' + value.branch
          obj.id = (this.userData.class2[snf]).toString()
          obj.name = value.grade
          obj.section = value.branch
          obj.campus_id = this.userData.campus_idx.toString()
          obj.campus_name = this.userData.campus_name
          obj.institution_id = this.userData.school_id.toString()
          obj.institution_names = this.userData.school_namex
          this.payloads.groups[index] = obj
        })
      }
    },

    goToEFS: async function () {
      // *** important:
      // •	if both email and username are not provided = then error
      // •	if both email and user are provided = check if valid email (if no then error) If valid then register through the email provided.
      // •	if only username is provided = register using that
      // •	if only email is provided = error if invalid, else register using that.
      try {
        await this.setUserDatas();
        /**/
        this.setTokenExpiredTime();
        this.setTokenIssuedTime();
        const tokenApiUrl = "https://api.myeduquiz.com/api/v0.0.1/user/get-efc-token";
        const efsToken = await axios.post(tokenApiUrl, {
          payloadEFC: this.payloads,
          headersJWT: this.headers,
          algorithmKey: 'RS256'
        });
        const token = efsToken?.data?.token || "";
        // console.log('userData', this.userData)
        // console.log('payloads', this.payloads)

        if (token) {
          // This url will use for testing purposes.
          // window.open("https://serenity-dev.elltechnologies.com/oauth2/sso/UES_EFS" + `?id_token=${token}`, "_blank");

          // test linki
          // location.href = "https://serenity-dev.elltechnologies.com/oauth2/sso/UES_EFS" + `?id_token=${token}`;

          // prod linki
          // console.log('payloads', this.payloads)
          location.href = "https://learn.elltechnologies.com/oauth2/launch/" + `${this.userData.efs_aud}` + `?id_token=${token}`;

          // This url can use to test on frontend side.
          // window.open("https://serenity-dev.elltechnologies.com/oauth2/launch/UES_EFS?id_token=" + `${token}`, "_blank");

          //This url will use in production.
          // window.open("https://learn.elltechnologies.com/oauth2/sso/UES_EFS" + `?id_token=${token} `, "_blank");
        }
        /**/
      } catch (error) {
        console.log(error);
      }
    },

    async ueslmsCheckUser() {
      await axios.post('https://www.ueslms.com/api/v2/efs/user/check', {user: this.userData})
          .then(response => {
            if (response.data.success) {
              this.setUesLmsUserData(response.data)
              this.goToEFS()
            } else {
              this.ueslmsCreate()
            }
          })
          .catch(response => {
            console.log('ERR', response)
          })
    },

    setUesLmsUserData(val) {
      this.userData.user_idx = val.user.user_id;
      this.userData.class_id = val.user.class_id;
      this.userData.class_ids = val.user.class_ids;
      this.userData.class2 = val.user.class;
      this.userData.campus_idx = val.user.campus_id;
      this.userData.school_namex = val.user.school_name;
      this.userData.efs_aud = val.user.efs_aud;
      if (this.userData.role_type	=== 'student') {
        this.userData.grade = val.user.grade;
        this.userData.branch = val.user.branch;
      }
    },

    ueslmsCreate() {
      axios.post('https://www.ueslms.com/api/v2/hlllogin/user/create', this.userData)
          .then(response => {
            this.setUesLmsUserData(response.data)
            this.goToEFS()
          })
          .catch(response => {
            console.log('ERR', response)
          })
    },
  },
}
</script>
